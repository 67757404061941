.MainName {
  .shape {
    box-shadow: inset 0px 0px 24px #000000;
    width: 200vw;
    height: 200vh;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-10%, -50%) skew(-30deg);
  }
  .content {
    color: black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(10%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .title {
      font-size: 38px;
    }
    .subtitle {
      color: #333333;
      margin-bottom: 10vh;
    }
    .contact {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 16px;
        .text {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .MainName {
    .shape {
      top: 40%;
    }
    .content {
      left: 45%;
    }
  }
}

@media (max-width: 600px) {
  .MainName {
    .shape {
      top: 40%;
    }
    .content {
      left: 30%;
    }
  }
}

@media (max-width: 425px) {
  .MainName {
    .shape {
      top: 30%;
    }
    .content {
      left: 25%;
    }
  }
}

@media (max-width: 375px) {
  .MainName {
    .shape {
      top: 25%;
    }
    .content {
      left: 15%;
    }
  }
}

@media (max-width: 320px) {
  .MainName {
    .shape {
      top: 20%;
    }
    .content {
      left: 0;
    }
  }
}
