.Particle {
  background: -webkit-linear-gradient(
    45deg,
    rgba(73, 155, 234, 1) 0%,
    rgba(0, 16, 120, 1) 69%,
    rgba(0, 16, 120, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(73, 155, 234, 1) 0%,
    rgba(0, 16, 120, 1) 69%,
    rgba(0, 16, 120, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(73, 155, 234, 1) 0%,
    rgba(0, 16, 120, 1) 69%,
    rgba(0, 16, 120, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(73, 155, 234, 1) 0%,
    rgba(0, 16, 120, 1) 69%,
    rgba(0, 16, 120, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#001078', GradientType=1 );
  height: 100%;
}
